import $ from 'jquery';
import autosize from 'autosize'

/**
 * Wrap Tables
 * @url https://foundation.zurb.com/sites/docs/table.html#scrolling-table
 */
// Get all table elements
const tables = document.querySelectorAll('.main table');

// create wrapper container
const wrapper = document.createElement('div');
wrapper.classList.add('table-scroll');

// insert wrapper before el in the DOM tree
tables.forEach((table) => {
  table.parentNode.insertBefore(wrapper, table);
  wrapper.appendChild(table);
});

/**
 * Responsive Embeds
 * @url https://foundation.zurb.com/sites/docs/responsive-embed.html
 */
$('iframe[src*="youtube"]').wrap("<div class='responsive-embed widescreen'/>");
$('iframe[src*="vimeo"]').wrap("<div class='responsive-embed widescreen vimeo'/>");
$('iframe[src*="dailymotion"]').wrap("<div class='responsive-embed widescreen dailymotion'/>");

/**
* Autosize Textarea
* @url https://www.jacklmoore.com/autosize/
*/
const textareas = document.querySelectorAll('textarea.js-autosize')
autosize(textareas)
