window.addEventListener('load', function() {

  var postsList = document.querySelector('.js-posts-list');

  if(!postsList)
    return;

  window.paged = 1;
  var cpt = postsList.dataset.cpt;
  var maxnumpages = document.querySelector('.js-post-list-item').dataset.maxnumpages;
  var loadMoreButton = document.querySelector('.js-load-more');
  var xhr;
  var loadPostsAjaxUrl;

  // On Load More Click, increment paged & load Courses
  loadMoreButton.addEventListener('click', function(){

    // Disabled during ajax load
    if(loadMoreButton.classList.contains('disabled'))
      return;

    window.paged++;

    loadPosts();
  });


  function loadPosts(){

    // Disable loadMoreButton during ajax load
    loadMoreButton.classList.add('disabled');

    // Ajax Request
    xhr = new XMLHttpRequest();
    loadPostsAjaxUrl = ajaxurl
      + '?action=load_'+cpt
      + '&paged=' + window.paged
    xhr.open('GET', loadPostsAjaxUrl);
    xhr.onload = function() {
      if (xhr.status === 200) {

        // Add page results to list
        postsList.innerHTML += xhr.responseText;

        // On 1st page, set maxnumpage
        if(window.paged == 1)
          maxnumpages = document.querySelector('.js-posts-list-item').getAttribute('data-maxnumpages');

        // Show LoadMoreButton if more Pages, or Hide
        loadMoreButton.style.display = (window.paged >= maxnumpages) ? 'none' : 'inline-block';

        // Enable loadMoreButton after ajax load
        loadMoreButton.classList.remove('disabled');
      }
    };
    xhr.send();
  }

});
