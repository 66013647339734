/**
 * Gravity Form
 * Adds Material Design on Gravity Forms
 * @dependencies jQuery
 * @dependencies autosize https://github.com/jackmoore/autosize
 */

import $ from 'jquery'
import autosize from 'autosize'

// Wrapper closure for events
const $form = $(".gform_wrapper");

if ($form.length) {

  /**
   * Material UI
   * par défaut tous les formulaires gravity sont en floatting labels
   */
  $form.addClass("is-material-ui");

  /**
   * Webkit Autofill Detection Hack
   * inspiré de https://github.com/klarna/ui/blob/v4.10.0/Field/index.jsx#L104-L114
   */
  const inputs = document.querySelectorAll('.is-material-ui [type=text], .is-material-ui [type=password]')
  const onAutoFillStart = (el) => {
    const $field = $(el).closest('li')
    if ($field.hasClass('gfield-password')) {
      $(el).parent().addClass('has-label')
    } else {
      $field.addClass('has-label')
    }
  }
  const onAutoFillCancel = (el) => el.parentNode.classList.remove('has-label')

  const onAnimationStart = ({ target, animationName }) => {
    switch (animationName) {
    case 'onAutoFillStart':
      return onAutoFillStart(target)
    case 'onAutoFillCancel':
      return onAutoFillCancel(target)
    }
  }

  for (const input of inputs) {
    input.addEventListener('animationstart', onAnimationStart, false)
  }

  /**
   * Floating labels events
   */
  // Regular Fields
  // Input Text, On Focus
  $form.on("focus", "[type=text], .gfield-text [type=password], textarea", function() {
    $(this).parents(".gfield").addClass('is-focused has-label');
  });
  // Input Text : On blur
  $form.on("blur", "[type=text], .gfield-text [type=password], textarea", function() {
    const $parent = $(this).parents(".gfield");
    if ($(this).val() === '') {
      $parent.removeClass('has-label');
    }
    $parent.removeClass('is-focused');
  });
  // Input Text :  Si un champs est rempli on rajoute directement la class has-label
  $form.on("change", "[type=text], .gfield-text [type=password], textarea", function() {
    if ($(this).val() !== '') {
      $(this).parents(".gfield").addClass('has-label');
    }
  });

  // Complex Fields
  // On Focus
  $form.on("focus", ".ginput_complex [type=password]", function() {
    $(this).parent().addClass('is-focused has-label');
  });
  // On blur
  $form.on("blur", ".ginput_complex [type=password]", function() {
    const $parent = $(this).parent();
    if ($(this).val() === '') {
      $parent.removeClass('has-label');
    }
    $parent.removeClass('is-focused');
  });
  // Input Text :  Si un champs est rempli on rajoute directement la class has-label
  $form.on("change", ".ginput_complex [type=password]", function() {
    if ($(this).val() !== '') {
      $(this).parent().addClass('has-label');
    }
  });

  // Input Radio : Toggle
  // $form.on("click", ".wpcf7-list-item input[type=radio]", function() {
  //   var $self = $(this);
  //   $self.parents(".wpcf7-radio").find(".is-checked").removeClass("is-checked");
  //   if ( $self.is(':checked') ) {
  //     $self.parents(".wpcf7-list-item").addClass('is-checked');
  //   }
  //   // Specific Conditionnal
  //   if( $self.parents(".input__mui").hasClass("has_url") ){
  //     var $site_url = $(".input__mui.content_url");
  //     if($self.val() === "Oui"){
  //       $site_url.show();
  //     } else {
  //       $site_url.hide();
  //     }
  //   }
  // });

  // Input Checkbox
  $form.on("click", "[type=checkbox]", function(){
    if ( $(this).is(':checked') ) {
      $(this).parents("li").addClass('is-checked');
    } else {
      $(this).parents("li").removeClass('is-checked');
    }
  })

  /**
   * Textarea auto size
   * We remove textarea rows attribute only because we have Autosize to resize it.
   * We're doing it in JS because there's no other way to do it with Gravity :sweat:
   */
  const textareas = $form[0].querySelectorAll('textarea')
  for (const el of textareas) {
    el.removeAttribute('rows')
    autosize(el)
  }
}
