/**
 * Searchbar Module
 * @dependencies gsap
 */

import { TimelineLite, Power3 } from 'gsap';

// DOM Elements
const searchModule = document.querySelector('.search-module');

if (searchModule) {
  const searchDisplay = document.querySelector('.js-search-module-display');
  const searchToggle = searchModule.querySelectorAll('.js-search-module-open, .js-search-module-close, .js-search-module-backdrop');
  const searchBg = searchModule.querySelector('.js-search-module-bg');
  const searchBackdrop = searchModule.querySelector('.js-search-module-backdrop');
  const searchContent = searchModule.querySelector('.js-search-module-content');
  const searchClose = searchModule.querySelector('.js-search-module-close');

  // Events
  let searchOpened = false;
  if (searchToggle.length){
    searchToggle.forEach((el) => {
      el.addEventListener('click', (ev) => {
        ev.preventDefault();

        if (searchOpened) {
          // Closing Tween
          const tl = new TimelineLite();
          tl.to(searchBg, .5,{ scaleY: 0, ease: Power3.easeOut}, 'start');
          tl.to(searchContent, .5, { y: -80, autoAlpha: 0, ease: Power3.easeOut}, 'start+=0');
          tl.to(searchBackdrop, .5, { autoAlpha: 0 }, 'start+=0');
          tl.to(searchClose, .2, { autoAlpha: 0 }, 'start+=0');
          tl.set(searchDisplay, { autoAlpha: 0 });
          searchOpened = false;

        } else {
          // Opening Tween
          const tl = new TimelineLite();
          tl.set(searchDisplay, { autoAlpha: 1 }, 'start');
          tl.fromTo(searchBackdrop, .5, { autoAlpha: 0 }, { autoAlpha: 1 }, 'start+=0');
          tl.fromTo(searchBg, .5, { scaleY: 0, ease: Power3.easeOut }, { scaleY: 1, ease: Power3.easeOut }, 'start+=0');
          tl.fromTo(searchClose, .5, { y: -80, autoAlpha: 0, ease: Power3.easeOut }, { y: 0, autoAlpha: 1, ease: Power3.easeOut }, 'start+=0.1');
          tl.fromTo(searchContent, .5, { y: -80, autoAlpha: 0, ease: Power3.easeOut }, { y: 0, autoAlpha: 1, ease: Power3.easeOut }, 'start+=0.15');
          searchOpened = true;
        }
      })
    });
  }
}
