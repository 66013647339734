/**
 * In Viewport detection
 * (to trigger some animations)
 * @dependencies scrollmonitor
 */

// Animate on Scroll
import scrollMonitor from 'scrollmonitor'

class animateWhenVisible {

  constructor(el) {
    this.el = el
    this.offset = -50
    this.delay = 0
    this.watcher = null
    this.init()
    this.attachEvents()
  }

  init () {
    this.offset = this.el.dataset.animateOffset ? this.el.dataset.animateOffset*1 : this.offset
    this.delay = this.el.dataset.animateDelay ? this.el.dataset.animateDelay*1 : this.delay
    this.watcher = scrollMonitor.create( this.el, this.offset )
  }

  attachEvents () {
    this.watcher.enterViewport(() => this.onEnter())
    // this.watcher.exitViewport(() => this.onExit())
  }

  onEnter () {
    window.setTimeout(() => {
      this.el.classList.add('in-viewport')
    }, this.delay)

  }

  // onExit () {
  //   this.el.classList.remove('is-visible')
  // }
}

// Init
const blocks = document.querySelectorAll('[data-animated]')
for (const block of blocks) {
  new animateWhenVisible(block)
}
