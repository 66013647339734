import lozad from 'lozad'

document.addEventListener("DOMContentLoaded", function() {

  const observer = lozad(); 
  observer.observe();

  setTimeout(function(){ 
    const observer = lozad(); 
    observer.observe();
  }, 3000);

});
