document.addEventListener("DOMContentLoaded", function() {

  var input_login    = document.querySelector('#loginform input[id="user_login"]');
  let input_password = document.querySelector('#loginform input[id="user_pass"]');
  var tooltip        = document.querySelector('#login-form-tooltip');
  var eye            = document.querySelector('#login-form-eye');
  var input_password_type;

  // Set inputs placeholders
  input_login.setAttribute('placeholder', "Identifiant");
  input_password.setAttribute('placeholder', "Mot de passe");

  // Move tooltip & eye button after each input
  input_login.after(tooltip);
  input_password.after(eye);

  // Toggle password visibility on click on eye button
  eye.addEventListener('click', function(){
    input_password_type = input_password.getAttribute('type') == 'text' ? 'password' : 'text';
    input_password.setAttribute('type', input_password_type);
  });

});
