/**
 * Polyfills
 * - CSS object-fit
 * - NodeList.forEach()
 * - Element.closest()
 */

// Object-fit polyfill for edge & ios9 https://github.com/bfred-it/object-fit-images
import objectFitImages from 'object-fit-images';
objectFitImages();

// NodeList.forEach()
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

// Element.closest()
if (!Element.prototype.matches)
  Element.prototype.matches = Element.prototype.msMatchesSelector ||
          Element.prototype.webkitMatchesSelector;
if (!Element.prototype.closest)
  Element.prototype.closest = function (s) {
    var el = this;
    if (!document.documentElement.contains(el)) return null;
    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null);
    return null;
  };
