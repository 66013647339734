/**
 * Mobile Menu
 * @dependencies jQuery
 * @dependencies Foundation
 */

import $ from 'jquery';
import { Drilldown } from 'foundation-sites';

// We dont need instant load, so we can keep `load` event lifecycle
window.addEventListener('load', function(event) {

  // ### DrillDown Menu
  // Settings
  const $triggersMenu = $('.js-responsive-menu-toggle');

  if ($triggersMenu.length) {
    console.log('test');

    const drillDownEl = $(".js-responsive-menu > ul");
    const drillDownBackText = drillDownEl.attr('data-back');
    const drillDownOptions = {
      backButton: `<li class="js-drilldown-back"><a class="drilldown-back" tabindex="0">${drillDownBackText}</a></li>`,
      // closeOnClick: true,
      parentLink: true
      //scrollTop: true,
      //autoHeight: true,
      //animateHeight: true
    };

    // Init
    const drillDownInstance = new Drilldown(drillDownEl, drillDownOptions);

    // ### Toggle Open/Close
    $triggersMenu.on('click', function(ev){
      ev.preventDefault();
      $('body').toggleClass('nav-open');
    });
  }

});
